import * as React from "react"
import clsx from "clsx"
import { Link } from "gatsby"

// markup
const FilterItem = (props) => {
    const style = clsx(
        "mr-4",
        "font-sans",
        "text-xs",
        "uppercase",
        "italic", 
        "text-ford-grey",
        "hover:text-battleship-grey",
        {
            "text-battleship-grey": props.active,
            "font-medium": props.active
        }
    )

    return (

        <Link className={style} to={props.to}>{props.children}</Link>
    )
}

export default FilterItem
