import * as React from "react"
import { Link } from "gatsby"

// markup
const ProjectThumbnail = (props) => {
   
    return ( 
        <Link to={props.to}>
            <div className="max-w-screen-sm text-center max-h-96 mt-4 md:mt-0 relative group">
                {props.children}
                <div className="bg-white absolute bottom-0 w-full text-center opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                    <h2 className="text-sans text-sm font-normal text-battleship-grey my-2">{props.title}</h2>
                </div>
            </div>
        </Link>
    )
}

export default ProjectThumbnail
