import * as React from "react"
import clsx from "clsx"
// markup
const HeaderItem = (props) => {
    const style = clsx(
        "font-sans",
        "text-sm",
        "font-normal",
        "text-battleship-grey",
        "text-center",
        "my-2",
    )
return (
        <p className={style} >{props.children}</p>
    )
}

export default HeaderItem
